import React from "react"
import addToMailchimp from "gatsby-plugin-mailchimp"

const NewsletterSignUp = () => {
  const [email, setEmail] = React.useState("")
  const [success, setSuccess] = React.useState(false)
  const [error, setError] = React.useState("")

  const handleSubmit = async e => {
    e.preventDefault()
    const result = await addToMailchimp(email)
    if (result.result === "success") setSuccess(true)
    else setError(result.msg)
  }

  const getText = () => {
    if (error) return error
    if (success) return "Thanks! Check your inbox for updates."
    return "Get new posts in your inbox."
  }

  return (
    <div className="flex justify-center w-full">
      <form onSubmit={e => handleSubmit(e)}>
        <div className="flex flex-col pt-4 pb-6 px-6 border-2 rounded-sm border-black shadow-xs">
          <label className="block mb-3" htmlFor="email-input">
            {getText()}
          </label>
          <div className="flex ">
            <input
              className="border shadow-sm p-2"
              placeholder="you@are.awesome"
              onChange={e => setEmail(e.target.value)}
              value={email}
            />
            <button
              disabled={success}
              className="py-2 px-2 border-4 w-20 border-black ml-4 shadow-lg"
              type="submit"
            >
              Pow
            </button>
          </div>
        </div>
      </form>
    </div>
  )
}

export default NewsletterSignUp
